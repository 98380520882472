import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { routes } from '@constants/routes'
import { Box } from '@mui/system'
import { NavTabItem, NavTabs } from '@controls/NavTabs'

export const SettingsPage: React.FC = () => {
  const { t } = useTranslation()

  const tabs: ReadonlyArray<NavTabItem> = [
    {
      label: t('pages.admin.settings.title'),
      to: routes.Admin.SettingsProjectSettings
    },
    {
      label: t('pages.admin.energy_classes.title'),
      to: routes.Admin.SettingsEnergyClasses
    },
    {
      label: t('pages.admin.forms_of_ownership.title'),
      to: routes.Admin.SettingsFormsOfOwnership
    },
    {
      label: t('pages.admin.property_types.title'),
      to: routes.Admin.SettingsPropertyTypes
    }
  ]

  return (
    <div>
      <h1>{t('pages.admin.settings.title')}</h1>
      <NavTabs tabs={tabs} />
      <Box pt={2}>
        <Outlet />
      </Box>
    </div>
  )
}
